import { Icon } from "@twilight-toolkit/ui"
import Head from "next/head"
import Image from "next/image"
import Link from "next/link"
import React, { useState } from "react"
import PagesAndLinks from "~/components/Banners/PagesAndLinks"
import { pageLayout } from "~/components/Page"
import Top from "~/components/Top"
import { NextPageWithLayout } from "~/pages/_app"
import PostCard from "~/components/Card/Post";
import useSWR from "swr";
import fetcher from "~/lib/fetcher";
import CardSkeleton from "~/components/Card/Skeleton";
import {useHorizontalScroll} from "~/utilities/horizontalScroll";
import Employment from "~/components/Employment";
import BlogSection from "~/components/Blog";
import StoriesSection from "~/components/Stories";
import Status from "~/components/Status";

const Emphasis = ({
	name,
	className,
	children,
}: {
	name: string
	className?: string
	children?: React.ReactNode
}) => (
	<span
		className={`${className || ""} inline-flex items-center gap-x-2 rounded-md border border-gray-300 bg-white px-[8px] py-0.5 text-sm font-normal tracking-normal dark:border-gray-600 dark:bg-gray-700 lg:py-1`}>
		{children ? (
			<>
				<span className="border-r border-gray-300 pr-2 dark:border-gray-600">
					{name}
				</span>
				<span>{children}</span>
			</>
		) : (
			<span>{name}</span>
		)}
	</span>
)

const Home: NextPageWithLayout = () => {
	return (
		<>
			<Head>
				<title>David M. Coleman</title>
			</Head>
			<section className="mt-0 pt-24 lg:mt-20 lg:pt-0">
				<div className="flex items-center justify-between gap-x-10 gap-y-8">
					<div className="hidden flex-shrink-0 pt-1 lg:block">
						<Image
							src="/static/profile/me.jpg"
							height={131}
							width={131}
							alt="David M. Coleman"
							className="rounded-md bg-gray-200 shadow-sm dark:border dark:border-gray-600 rotate-6 translate-x-1 -translate-y-3"
						/>
					</div>
					<div className="flex flex-col gap-y-1">
						<h1 className="flex items-center whitespace-nowrap break-words text-3xl font-medium tracking-wide text-black dark:text-white lg:text-[1.8rem]">
							<span
								className="mr-2.5 inline-block animate-waveHand cursor-pointer hover:animate-waveHandAgain">
								👋
							</span>
							Howdy, I{"'"}m David!
							<span className="ml-auto pl-2.5 flex items-center">
								<Status />
							</span>
						</h1>
						<div
							className="flex flex-col gap-y-1.5 break-words px-1 text-justify text-4 font-light leading-relaxed tracking-wide text-gray-500 dark:text-gray-300 lg:text-2">
							<p>
								My name is David M. Coleman, and I am an aspiring writer &amp; blogger currently
								residing in the beautiful Hill Country outside{" "}
								<a
									href="https://www.google.com/maps/place/Austin%2C%20TX/"
									target="_blank"
									className="inline-flex items-center gap-x-1.5 transition-colors text-gray-700 dark:text-gray-100 hover:text-blue-500 dark:hover:text-blue-500">
									Austin, Texas
								</a>
								<Image
									alt="Texas flag"
									src="/static/flags/us-tx.png"
									width={18}
									height={12}
									className="inline w-[18px] h-[12px] -translate-y-px ml-1.5 mr-0.5"
								/>
								{"."}
							</p>
							{/* <p>
								I was previously a{" "}
								<Emphasis
									name="Full Stack Software Engineer"
									className="hidden border-l-4 !border-l-blue-400 lg:inline-flex">
									Intern
								</Emphasis>
								<span className="lg:hidden">Full Stack Software Engineer</span>{" "}
								at various technology startups based in Toronto, Canada 🇨🇦.
							</p> */}
						</div>
					</div>
				</div>
			</section>
			<section className="hidden lg:block mt-10">
				{/* <label className="border-gray-301 inline-flex items-center rounded-full border bg-white px-4 py-[4px] font-medium tracking-wider shadow-sm dark:border-gray-600 dark:bg-gray-700">
					<span className="mr-2.5 flex h-5 w-5 text-green-500">
						<Icon name="plane" />
					</span>
					<span className="uppercase">How to Reach Me</span>
				</label> */}
				<div className="mt-6">
					<Top/>
				</div>
			</section>
			<section className="mt-6">
				{/* <label className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-[4px] font-medium tracking-wider shadow-sm dark:border-gray-600 dark:bg-gray-700">
					<span className="mr-1.5 flex h-5 w-5 text-yellow-400">
						<Icon name="flag" />
					</span>
					<span className="uppercase">Featured Content</span>
				</label> */}
				<div className="mt-5">
					{/* <ResearchPapers /> */}
					<div className="mt-4">
						<PagesAndLinks/>
					</div>
				</div>
			</section>
			<section className="mt-16">
				<div className="flex items-center justify-between">
					<label
						className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-[4px] font-medium tracking-wider shadow-sm dark:border-gray-600 dark:bg-gray-700">
						<span className="mr-1.5 flex h-5 w-5">
							<Icon name="horse"/>
						</span>
						<span className="hidden uppercase lg:block">
							Interests
						</span>
						<span className="block uppercase lg:hidden">Interests</span>
					</label>
					<Link
						href="/about"
						target="_self"
						className="flex items-center gap-x-1 text-gray-500 underline-offset-4 transition-colors hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-500">
						About me
						<span className="h-5 w-5 underline">
							<Icon name="arrowRight"/>
						</span>
					</Link>
				</div>
				<div
					className="mt-[15px] flex flex-col gap-y-2 break-words px-0.5 text-justify text-3 font-light leading-relaxed tracking-wide text-gray-500 underline-offset-[6px] dark:text-gray-300 lg:text-[17px]">
					<p>
						<span>
							I am a <i>somewhat</i> active member of both the{" "}
							<u className="decoration-gray-300/25">
								<a className="font-medium text-gray-700 dark:text-gray-50 decoration-transparent hover:decoration-blue-500 transition-colors"
								   href={"https://en.wikipedia.org/wiki/My_Little_Pony:_Friendship_Is_Magic_fandom"}
								   target={"_blank"}>brony</a> and <a
								className="font-medium text-gray-700 dark:text-gray-100 decoration-transparent hover:decoration-blue-500 transition-colors"
								href={"https://en.wikipedia.org/wiki/Furry_fandom"}
								target={"_blank"}>furry</a> fandoms
							</u>
						</span>.
						<span>
							{" "}
							I became a brony in October of 2014, and slowly eased my way into being a furry starting around 2015 – 2016, but hadn{"'"}t fully admitted it to myself until about 2018. You <i>won{"'"}t</i> catch me wearing a fursuit however, as those are far too creepy.
						</span>
					</p>
					<p className="mt-5">All that said, I have a variety of other interests as well:</p>
					<ul className="mt-2 list-disc pl-5">
						<li className="pl-3">
							<strong className="font-semibold">Heavy Metal</strong>: Avenged Sevenfold, Nightwish, Epica,
							Almanac, etc.
						</li>
						<li className="pl-3">
							<strong className="font-semibold">RPGs</strong>: The Elder Scrolls, Fallout.
						</li>
						<li className="pl-3">
							<strong className="font-semibold">Film</strong>: The Lord of the Rings, Deadpool, The Big
							Lebowski, John Wick, Airplane!, The Princess Bride, Monty Python, etc.
						</li>
						<li className="pl-3">
							<strong className="font-semibold">Television</strong>: MLP: FiM, Fallout, Helluva Boss, etc.
						</li>
					</ul>
				</div>
			</section>
			<section className="mt-14">
				<div className="flex items-center justify-between">
					<label
						className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-[4px] font-medium tracking-wider shadow-sm dark:border-gray-600 dark:bg-gray-700">
						<span className="mr-1.5 flex h-5 w-5">
							<Icon name="blog"/>
						</span>
						<span className="hidden uppercase lg:block">
							Blog
						</span>
						<span className="block uppercase lg:hidden">Blog</span>
					</label>
					<Link
						href="/blog"
						target="_self"
						className="flex items-center gap-x-1 text-gray-500 underline-offset-4 transition-colors hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-500">
						View all
						<span className="h-5 w-5 underline">
							<Icon name="arrowRight"/>
						</span>
					</Link>
				</div>
				<BlogSection />
			</section>
			<section className="mt-14">
				<div className="flex items-center justify-between">
					<label
						className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-[4px] font-medium tracking-wider shadow-sm dark:border-gray-600 dark:bg-gray-700">
						<span className="mr-1.5 flex h-5 w-5">
							<Icon name="bookBookmark"/>
						</span>
						<span className="uppercase">
							Stories
						</span>
					</label>
					<Link
						href="https://www.fimfiction.net/user/221653/David+M+Coleman"
						target="_blank"
						className="flex items-center gap-x-1 text-gray-500 underline-offset-4 transition-colors hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-500">
						FiMFiction
						<span className="h-5 w-5 underline">
							<Icon name="externalLink"/>
						</span>
					</Link>
				</div>
				<StoriesSection />
			</section>
			<section className="mt-14 mb-28">
				<div className="flex items-center justify-between">
					<label
						className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-[4px] font-medium tracking-wider shadow-sm dark:border-gray-600 dark:bg-gray-700">
						<span className="mr-1.5 flex h-5 w-5">
							<Icon name="briefcase"/>
						</span>
						<span className="uppercase">Jobs</span>
					</label>
					{/*<Link
						href="https://linkedin.com/in/wolfheadtv/"
						target="_blank"
						className="flex items-center gap-x-1 text-gray-500 underline-offset-4 transition-colors hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-500">
						LinkedIn
						<span className="h-5 w-5 underline">
							<Icon name="externalLink"/>
						</span>
					</Link>*/}
				</div>
				<Employment/>
			</section>
			{/* <section className="mt-14">
				<div className="flex items-center justify-between">
					<label className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-[4px] font-medium tracking-wider shadow-sm dark:border-gray-600 dark:bg-gray-700">
						<span className="mr-1.5 flex h-5 w-5 text-rose-500">
							<Icon name="suitcase" />
						</span>
						<span className="uppercase">Education</span>
					</label>
				</div>
				<div className="mt-5 flex flex-col gap-y-4">
					<EmploymentCard
						orgLogoSrc="https://static.ouorz.com/uwaterloo_logo.webp"
						organization="University of Waterloo"
						organizationFullName="Bachelor of Mathematics, Honours, Co-operative Program (Minor in Computing)"
						jobTitle="Mathematics, Combinatorics & Optimization"
						jobType="Undergraduate"
						dateString="Sep 2020 - Apr 2025"
					/>
					<EmploymentCard
						orgLogoSrc="https://static.ouorz.com/ntu_logo.webp"
						organization="Nanyang Technological University"
						organizationFullName="GEM Trailblazer Exchange Program"
						jobTitle="Mathematical Sciences"
						jobType="Exchange"
						dateString="Aug - Dec 2023"
					/>
				</div>
			</section> */}
			{/*<div id="writings"></div>
			<section className="mb-24 mt-14">
				<div className="flex justify-between">
					<label className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-[4px] font-medium tracking-wider shadow-sm dark:border-gray-600 dark:bg-gray-700">
						<span className="mr-1.5 flex h-5 w-5">
							<Icon name="edit" />
						</span>
						<span className="uppercase">My Writings</span>
					</label>
					<button
						data-cy="showIndexPosts"
						onClick={() => setShowPosts(!showPosts)}
						className={`flex items-center gap-x-1 text-gray-500 underline-offset-4 transition-all hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-500 ${showPosts ? 'opacity-0 pointer-events-none' : ''}`}>
						Load entries
						<span className="h-5 w-5 underline">
							<Icon name="arrowDown" />
						</span>
					</button>
				</div>
				{showPosts ? (
					<div className="mt-5 animate-appear">
						<List type="index" />
					</div>
				) : (
					<div
						className="mt-5 flex flex-col gap-y-2 break-words px-0.5 text-justify text-3 font-light leading-relaxed tracking-wide text-gray-500 underline-offset-[6px] dark:text-gray-300 lg:text-[17px]">
						<p>
							This is my list of writings from the various places I have written them. Whether it be from my personal blog, someone else{"'"}s, or a contribution to a major publisher; you can find it here.
						</p>
					</div>
				)}
			</section>*/}
		</>
	)
}

Home.layout = pageLayout

export default Home
