import React from "react";
import Parse from "html-react-parser";
import TimeAgo from "~/components/Helpers/timeAgo";

const PostCard = (props: any) => {
    const { title, slugPath, date, cover, excerpt, host } = props

    return (
        <div
            onClick={() => {
                window?.open(`${host}/blog/${slugPath}`, "_self")
            }}
            className="group flex w-full cursor-pointer flex-col gap-y-2 rounded-md border bg-white shadow-sm transition-all hover:-translate-y-1 duration-300 hover:border-gray-300 dark:border-gray-700 dark:bg-gray-800 dark:hover:border-gray-600">
            <div
                className="text-normal flex w-full items-center border-b border-gray-200 group-hover:border-gray-300 px-4.5 py-2.5 tracking-wide text-gray-700 dark:border-gray-700 dark:text-white dark:group-hover:border-gray-600 transition-colors duration-300">
                <strong>{Parse(title)}</strong>
            </div>
            <div className="flex flex-col gap-y-2.5 px-4.5 py-1">
                <div className="text-sm tracking-wide text-gray-600 dark:text-gray-300 line-clamp-3">
                    {Parse(excerpt)}
                </div>
            </div>
            <div
                className="flex flex-col items-start gap-x-2.5 gap-y-2 text-xs text-gray-500 px-4.5 py-2.5 border-t border-inherit lg:flex-row lg:items-center">
                <div>
                        <span>
                            Published <TimeAgo date={date}/>
                        </span>
                </div>
            </div>
        </div>
    )
}

export default PostCard
