import { Button } from "@twilight-toolkit/ui"
import CalButton from "~/components/Buttons/Cal"

export default function Top() {
	return (
		<div className="mt-4 grid lg:grid-cols-5 lg:gap-3">
			<div className="col-span-3 col-start-1 hidden grid-cols-3 gap-3 lg:grid">
				<a target="_blank" href="https://bsky.app/profile/crossrambles.com" rel="noreferrer">
					<Button
						type="default"
						icon="bluesky"
						className="!w-full text-3 leading-14 text-blue-500">
						<span className="tracking-normal">Bluesky</span>
					</Button>
				</a>
				<a
					target="_blank"
					href="https://instagram.com/crossrambles"
					rel="noreferrer">
					<Button
						type="default"
						icon="instagram"
						className="!w-full text-3 leading-14 text-[#c13584]">
						<span className="tracking-normal">Instagram</span>
					</Button>
				</a>
				<a target="_blank" href="mailto:howdy@crossrambles.com" rel="noreferrer">
					<Button
						type="default"
						icon="email"
						className="!w-full text-3 leading-14 text-gray-500">
						<span className="tracking-normal">Email</span>
					</Button>
				</a>
			</div>
			<div className="grid grid-cols-2 gap-x-2 whitespace-nowrap lg:col-start-4 lg:col-end-6 lg:block">
				<a target="_blank" href="mailto:howdy@crossrambles.com" rel="noreferrer" className="!w-full lg:hidden">
					<Button
						type="default"
						icon="email"
						className="!w-full text-3 leading-14 text-gray-500">
						<span className="tracking-normal">Email</span>
					</Button>
				</a>
				<CalButton/>
			</div>
		</div>
	)
}
