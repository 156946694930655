import { JobsAPIParams } from "~/utilities/content";
import EmploymentCard from "~/components/Card/Employment";
import React, { useState } from "react";
import { useHorizontalScroll } from "~/utilities/horizontalScroll";
import useSWR from "swr";
import fetcher from "~/lib/fetcher";

export default function Employment() {
    const [maskClass, setMaskClass] = useState("mask-x-r")
    const scrollRef = useHorizontalScroll()

    const { data: jobs, error: jobsError, isLoading } = useSWR(
        "api/jobs",
        fetcher
    )

    if ( jobsError ) return <></>;

    return (
        <div className="mt-5 flex flex-col gap-y-4">
            <div
                ref={scrollRef}
                onScroll={(e) => {
                    const target = e.target as HTMLDivElement

                    let maskClass = ""
                    if (
                        target.scrollLeft > 0 &&
                        target.scrollLeft < target.scrollWidth - target.clientWidth
                    ) {
                        maskClass = "mask-x-full"
                    } else if (target.scrollLeft === 0) {
                        maskClass = "mask-x-r"
                    } else {
                        maskClass = "mask-x-l"
                    }

                    setMaskClass(maskClass)
                }}
                className={`currentEmployment flex flex-col lg:flex-row gap-y-4 lg:gap-x-4 whitespace-nowrap no-scrollbar max-lg:mask-none ${!isLoading && jobs.current.length > 1 ? `${maskClass} lg:overflow-x-auto` : ''}`}>
                {!isLoading && jobs.current.map((job: JobsAPIParams, index: number) => {
                    return (
                        <EmploymentCard
                            key={index}
                            orgLogoSrc={job.logo}
                            organization={job.name}
                            organizationFullName={job.org}
                            jobTitle={job.description}
                            jobType={job.role}
                            dateString={job.dates}
                            url={job.url}
                            count={jobs.current.length}
                        />
                    )
                })}
            </div>
            <div className={`pastEmployment flex flex-col gap-y-4 w-full`}>
                {!isLoading && jobs.past.map((job: JobsAPIParams, index: number) => {
                    return (
                        <EmploymentCard
                            key={index}
                            orgLogoSrc={job.logo}
                            organization={job.name}
                            organizationFullName={job.org}
                            jobTitle={job.description}
                            jobType={job.role}
                            dateString={job.dates}
                            url={job.url}
                        />
                    )
                })}
            </div>
        </div>
    )
}