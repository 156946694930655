import PostCard from "~/components/Card/Post";
import CardSkeleton from "~/components/Card/Skeleton";
import React from "react";
import useSWR from "swr";
import fetcher from "~/lib/fetcher";
import StoryCard from "~/components/Card/Story";

export default function StoriesSection() {
    const { data: myStories, error: myStoriesError } = useSWR(
        "api/stories?limit=5",
        fetcher
    )

    return (
        <>
            {myStories && !myStoriesError ? (
                <div className="mt-5 flex flex-col gap-y-4">
                    {myStories.map((story: any, index: number) => {
                        return (
                            <StoryCard
                                key={index}
                                title={story.title}
                                subtitle={story.subtitle}
                                authors={story.guestAuthors}
                                status={story.status}
                                date={story.published}
                                url={story.url}
                                description={story.description}
                            />
                        )
                    })}
                </div>
            ) : (
                <div className="mt-5">
                    <CardSkeleton/>
                </div>
            )}
        </>
    )
}