import PostCard from "~/components/Card/Post";
import CardSkeleton from "~/components/Card/Skeleton";
import React from "react";
import useSWR from "swr";
import fetcher from "~/lib/fetcher";

const host = process.env.NEXT_PUBLIC_BASENAME

export default function BlogSection() {
    const { data: myPosts, error: myPostsError } = useSWR(
        "api/postsAlt?limit=5",
        fetcher
    )

    return (
        <>
            {myPosts && !myPostsError ? (
                <div className="mt-5 flex flex-col gap-y-4">
                    {myPosts.map((post: any, index: number) => {
                        return (
                            <PostCard
                                key={index}
                                host={host}
                                {...post}
                            />
                        )
                    })}
                </div>
            ) : (
                <div className="mt-5">
                    <CardSkeleton/>
                </div>
            )}
        </>
    )
}