import TimeAgoLib from "react-timeago";
import { format as dateFormat } from "date-fns";

function isLessThanAWeekOld(date) {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    return new Date(date) >= oneWeekAgo;
}

interface Props {
    date: Date | string | number
    format?: string
}

export default function TimeAgo({date, format}: Props) {
    const returnFormat = format || "MMM. d, yyyy";
    if ( isLessThanAWeekOld(date) ) {
        return <><TimeAgoLib date={date} /></>;
    } else {
        return <>{dateFormat(new Date(date), returnFormat)}</>;
    }
}