import { getCalApi } from "@calcom/embed-react";
import { useEffect, useState } from "react";
import { useTheme } from "next-themes";
import { Button } from "@twilight-toolkit/ui";

export default function CalButton() {
    const { theme } = useTheme();
    let [namespace, setNamespace] = useState(0);
    let [currentTheme, setCurrentTheme] = useState('dark');

    useEffect(() => {
        setNamespace(namespace + 1);
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const systemTheme = mediaQuery.matches ? "dark" : mediaQuery.matches;
        setCurrentTheme((theme === "system" ? systemTheme : theme).toString());

        (async function () {
            const cal = await getCalApi({ namespace: `meeting-${namespace}` });
            cal("ui", {
                theme: currentTheme === "dark" ? "dark" : "light",
                hideEventTypeDetails: false,
                layout: "month_view",
            });
        })();
    }, [theme]);

    return (
        <Button
            data-cal-link="cross/meeting"
            data-cal-config={`{"theme":"${currentTheme === "dark" ? "dark" : "light"}"}`}
            type="default"
            icon="calendarSchedule"
            className="!w-full text-gray-500"
        >
            <span className="pl-0.5 text-4 leading-14 tracking-normal lg:text-3">
                Schedule a Call
            </span>
        </Button>
    );
}