export const PROFILES = [
	{
		key: "bluesky",
		return: {
			production: "crossrambles.com",
			development: "jamellebouie.net",
		},
	},
	{
		key: "youtube",
		return: {
			production: "UC0FcHe0DMTkIIwQHxBzIxxw",
			development: "UClFSU9_bUb4Rc6OYfTt5SPw",
		},
	},
	{
		key: "trakt",
		return: {
			production: "dadiocoleman",
			development: "dadiocoleman",
		},
	},
	{
		key: "fimfiction",
		return: {
			production: "https://www.fimfiction.net/user/221653/David+M+Coleman",
			development: "https://www.fimfiction.net/user/221653/David+M+Coleman",
		}
	},
	{
		key: "goodreads",
		return: {
			production: "82494120",
			development: "82494120",
		},
	},
	{
		key: "discord",
		return: {
			production: "198169754215645185",
			development: "198169754215645185",
		},
	},
	{
		key: "twitch",
		return: {
			production: "crossrambles",
			development: "welovegames",
		},
	},
]

export const FEEDS = [
	{
		url: "https://wolfheadtv.livejournal.com/data/rss",
		site: "LiveJournal",
		icon: "https://livejournal.com/favicon.ico",
	},
	{
		url: "https://crossrambles.com/feed.xml",
		site: "Cross Rambles",
		icon: "https://crossrambles.com/favicon.ico",
	},
	// {
	// 	url: "https://www.youtube.com/feeds/videos.xml?playlist_id=PLQ18ddnGjhNoMfMiEV1CnmqQDJjq8hVXn",
	// 	site: "YouTube",
	// 	icon: "https://youtube.com/favicon.ico",
	// },
]

export const PAGES = [
	{
		title: "Home",
		path: "/",
	},
	{
		title: "About",
		path: "/about",
	},
	{
		title: "Bookmarks",
		path: "/bookmarks",
	},
	{
		title: "Bookshelf",
		path: "/bookshelf",
	},
	{
		title: "TV Series",
		path: "/watching/series",
	},
	{
		title: "Stats",
		path: "/stats",
	},
	{
		title: "Friends",
		path: "/friends",
	},
	{
		title: "Pages",
		path: "/pages",
	},
	{
		title: "Places",
		path: "/places",
	},
	{
		title: "Things",
		path: "/things",
	},
]
