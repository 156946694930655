import React from "react";
import Link from "next/link"
import Parse from "html-react-parser";
import { format } from "date-fns";
import TimeAgo from "~/components/Helpers/timeAgo";

type Link = {
    name: string
    href: string
    map: any
}

interface StoryCardProps {
    title: string
    subtitle?: string
    authors: any
    status: string
    date: string | number
    url: string
    description: string
}

const StoryCard = (props: StoryCardProps) => {
    const { title, subtitle, authors, status, date, url, description } = props

    let formattedStatus: string;
    let color: string;
    switch (status) {
        case "complete":
            formattedStatus = "Completed";
            color = "border-green-300 bg-green-50 text-green-500 dark:border-green-700 dark:bg-green-800 dark:text-green-400";
            break;
        case "incomplete":
            formattedStatus = "Incomplete";
            color = "border-yellow-300 bg-yellow-50 text-yellow-500 dark:border-yellow-700 dark:bg-yellow-800 dark:text-yellow-400";
            break;
        case "canceled":
            formattedStatus = "Canceled";
            color = "border-red-300 bg-red-50 text-red-500 dark:border-red-700 dark:bg-red-800 dark:text-red-400";
            break;
        case "hiatus":
            formattedStatus = "Hiatus";
            color = "bg-gray-100 text-gray-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400";
            break;
    }

    return (
        <div
            onClick={() => {
                window?.open(url, "_blank")
            }}
            className="group flex w-full cursor-pointer flex-col gap-y-2 rounded-md border bg-white shadow-sm transition-all hover:-translate-y-1 duration-300 hover:border-gray-300 dark:border-gray-700 dark:bg-gray-800 dark:hover:border-gray-600">
            <div className="text-normal flex w-full items-center border-b border-gray-200 group-hover:border-gray-300 px-4.5 py-2.5 tracking-wide text-gray-700 dark:border-gray-700 dark:text-white dark:group-hover:border-gray-600 transition-colors duration-300">
                <strong>{title}</strong>
                {subtitle && (<span className="font-light opacity-70">: {subtitle}</span>)}
            </div>
            <div className="flex flex-col gap-y-2.5 px-4.5 pb-3.5 pt-1">
                <div className="text-sm tracking-wide text-gray-600 dark:text-gray-300 line-clamp-3">
                    {Parse(description)}
                </div>
                <div className="flex flex-row items-center gap-x-2.5 gap-y-2 text-xs text-gray-500">
                    <div
                        className={`rounded-full border px-2 py-0.5 text-xs ${color || ""}`}>
                        {formattedStatus}
                    </div>
                    <div>
                        <span>
                            Published <TimeAgo date={date} />
                        </span>
                    </div>
                    <div className="flex items-center justify-between overflow-x-auto whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 ml-auto">
                        {authors.map((link: any, index: number) => {
                            return (
                                <React.Fragment key={index}>
                                    <Link
                                        href={link.href}
                                        target="_blank"
                                        onClick={(e) => e.stopPropagation()}
                                        className="underline underline-offset-4 transition-colors hover:text-blue-500">
                                        <span>{link.name}{/* →*/}</span>
                                    </Link>
                                    {index < authors.length - 1 && <span className="mr-1.5 opacity-50">{","}</span>}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StoryCard
