// @ts-nocheck

import { useEffect, useRef } from 'react';

export default function Marquee({ timing = 60, speed = 6, direction = 'left', state, className = "", children }) {
    const marqueeRef = useRef(null);

    useEffect(() => {
        const marqueeElement = marqueeRef.current;

        if (marqueeElement) {
            if (state) {
                marqueeElement.stop(); // Pause the marquee
            } else {
                marqueeElement.start(); // Resume the marquee
            }
        }
    }, [state]);

    return (
        <marquee
            ref={marqueeRef}
            behavior="scroll"
            scrollamount={speed}
            scrolldelay={timing}
            direction={direction}
            class={className}
        >
            {children}
        </marquee>
    );
}
