import { useState } from "react";
import { Icon } from "@twilight-toolkit/ui"
import Link from "next/link"
import { useHorizontalScroll } from "~/utilities/horizontalScroll"

const PagesAndLinks = () => {
	const [show, setShow] = useState(false)
	const [maskClass, setMaskClass] = useState("mask-x-r")
    const scrollRef = useHorizontalScroll()

	return (
		<div className="w-full rounded-md border bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800">
			<div className="flex w-full items-center justify-between gap-x-2.5 border-b border-gray-200 px-4.5 py-2.5 dark:border-gray-700">
				<div className="flex items-center gap-x-[7px] text-[15px] font-medium tracking-wide text-gray-700 dark:text-white">
					<span className="h-4.5 w-4.5 lg:h-7 lg:w-7">
						<Icon name="cube" />
					</span>
					<span>Pages & Links</span>
				</div>
				<div className="-translate-y-[1.5px]">
					<label className="rounded-full border-[1.5px] bg-gray-100 pb-[2.5px] pl-[7px] pr-[6.5px] pt-[1.5px] text-[0.675rem] font-medium leading-[0.675rem] text-gray-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400">
						Pinned
					</label>
				</div>
			</div>
			<div className="flex items-center justify-between gap-x-2.5 overflow-y-hidden overflow-x-auto whitespace-nowrap px-4.5 py-4 text-sm text-gray-600 dark:text-gray-300 max-w-[620px]">
				<div
					ref={scrollRef}
					onScroll={(e) => {
						const target = e.target as HTMLDivElement

						let maskClass = ""
						if (
							target.scrollLeft > 0 &&
							target.scrollLeft < target.scrollWidth - target.clientWidth
						) {
							maskClass = "mask-x-full"
						} else if (target.scrollLeft === 0) {
							maskClass = ""
						} else {
							maskClass = "mask-x-l"
						}

						setMaskClass(maskClass)
					}}
					className={`flex items-center gap-x-2.5 overflow-x-auto no-scrollbar ${maskClass}`}
				>
					<Link
						href="/blog"
						target="_self"
						className="effect-pressing flex items-center gap-x-[4px] rounded-md border px-3 py-1 shadow-sm hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-700 dark:hover:border-gray-600 dark:hover:bg-gray-600">
						<span className="h-4.5 w-4.5 lg:h-[16px] lg:w-[16px]">
							<Icon name="blog" />
						</span>
						<span className="flex items-center gap-x-2">
							<span>Blog</span>
							{/*<span className="text-xs tracking-tight text-gray-400">*/}
							{/*	(May not be up-to-date)*/}
							{/*</span>*/}
						</span>
					</Link>
					<Link
						href="/bookshelf"
						target="_self"
						className="effect-pressing flex items-center gap-x-[4px] rounded-md border px-3 py-1 shadow-sm hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-700 dark:hover:border-gray-600 dark:hover:bg-gray-600">
						<span className="h-4.5 w-4.5 lg:h-[16px] lg:w-[16px]">
							<Icon name="library" />
						</span>
						<span className="flex items-center gap-x-2">
							<span>Bookshelf</span>
							{/*<span className="text-xs tracking-tight text-gray-400">*/}
							{/*	(May not be up-to-date)*/}
							{/*</span>*/}
						</span>
					</Link>
					<Link
						href="/watching/series"
						target="_self"
						className="effect-pressing flex items-center gap-x-[4px] rounded-md border px-3 py-1 shadow-sm hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-700 dark:hover:border-gray-600 dark:hover:bg-gray-600">
						<span className="h-4.5 w-4.5 lg:h-[16px] lg:w-[16px]">
							<Icon name="tvClassic" />
						</span>
						<span className="flex items-center gap-x-2">
							<span>TV Series</span>
							{/*<span className="text-xs tracking-tight text-gray-400">*/}
							{/*	(May not be up-to-date)*/}
							{/*</span>*/}
						</span>
					</Link>
					<Link
						href="/bookmarks"
						target="_self"
						className="effect-pressing flex items-center gap-x-[4px] rounded-md border px-3 py-1 shadow-sm hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-700 dark:hover:border-gray-600 dark:hover:bg-gray-600">
						<span className="h-4.5 w-4.5 lg:h-[16px] lg:w-[16px]">
							<Icon name="bookmarksMulti" />
						</span>
						<span className="flex items-center gap-x-2">
							<span>Bookmarks</span>
							{/*<span className="text-xs tracking-tight text-gray-400">*/}
							{/*	(May not be up-to-date)*/}
							{/*</span>*/}
						</span>
					</Link>
					<Link
						href="/things"
						target="_self"
						className="effect-pressing flex items-center gap-x-[4px] rounded-md border px-3 py-1 shadow-sm hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-700 dark:hover:border-gray-600 dark:hover:bg-gray-600">
						<span className="h-4.5 w-4.5 lg:h-[16px] lg:w-[16px]">
							<Icon name="stack" />
						</span>
						<span className="flex items-center gap-x-2">
							<span>Things</span>
							{/*<span className="text-xs tracking-tight text-gray-400">*/}
							{/*	(May not be up-to-date)*/}
							{/*</span>*/}
						</span>
					</Link>
					{/*<Link*/}
					{/*	href="/places"*/}
					{/*	target="_self"*/}
					{/*	className="effect-pressing flex items-center gap-x-[4px] rounded-md border px-3 py-1 shadow-sm hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-700 dark:hover:border-gray-600 dark:hover:bg-gray-600">*/}
					{/*	<span className="h-4.5 w-4.5 lg:h-[16px] lg:w-[16px]">*/}
					{/*		<Icon name="rainbow" />*/}
					{/*	</span>*/}
					{/*	<span className="flex items-center gap-x-2">*/}
					{/*		<span>Places</span>*/}
					{/*	</span>*/}
					{/*</Link>*/}
					{/*<Link*/}
					{/*	href="/about"*/}
					{/*	target="_self"*/}
					{/*	className="effect-pressing flex items-center gap-x-[4px] rounded-md border px-3 py-1 shadow-sm hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-700 dark:hover:border-gray-600 dark:hover:bg-gray-600">*/}
					{/*	<span className="h-4.5 w-4.5 lg:h-[16px] lg:w-[16px]">*/}
					{/*		<Icon name="me" />*/}
					{/*	</span>*/}
					{/*	<span>About Me</span>*/}
					{/*</Link>*/}
					{/*<Link*/}
					{/*	href="/friends"*/}
					{/*	target="_self"*/}
					{/*	className="effect-pressing flex items-center gap-x-[4px] rounded-md border px-3 py-1 shadow-sm hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-700 dark:hover:border-gray-600 dark:hover:bg-gray-600">*/}
					{/*	<span className="h-4.5 w-4.5 lg:h-[16px] lg:w-[16px]">*/}
					{/*		<Icon name="people" />*/}
					{/*	</span>*/}
					{/*	<span>Friends</span>*/}
					{/*</Link>*/}
					<Link
						href="/stats"
						target="_self"
						className="effect-pressing flex items-center gap-x-[4px] rounded-md border px-3 py-1 shadow-sm hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-700 dark:hover:border-gray-600 dark:hover:bg-gray-600">
						<span className="h-4.5 w-4.5 lg:h-[16px] lg:w-[16px]">
							<Icon name="trend" />
						</span>
						<span>Stats</span>
					</Link>
					{/*<Link*/}
					{/*	href="https://youtube.com/@crossrambles"*/}
					{/*	target="_blank"*/}
					{/*	className="effect-pressing flex items-center gap-x-[4px] rounded-md border px-3 py-1 shadow-sm hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-700 dark:hover:border-gray-600 dark:hover:bg-gray-600">*/}
					{/*	<span className="h-4.5 w-4.5 lg:h-[16px] lg:w-[16px]">*/}
					{/*		<Icon name="youtube" />*/}
					{/*	</span>*/}
					{/*	<span>YouTube</span>*/}
					{/*</Link>*/}
					<span className="flex items-center h-4.5 pr-10"></span>
				</div>
				<div className="absolute inset-y-0 right-0 py-4 px-4.5">
					<div className={`more-gradient-btn ${show ? 'open' : 'closed'}`}>
						<Link
							onMouseEnter={() => setShow(true)}
							onMouseLeave={() => setShow(false)}
							href="/pages"
							aria-label="See all pages"
							className="group flex h-[25px] w-[25px] items-center justify-center overflow-hidden rounded-full bg-gray-100 text-gray-500 transition-all ease-in-out hover:w-[76px] dark:border dark:border-gray-600 dark:bg-gray-800 dark:text-gray-500 translate-y-px">
							<span className="effect-pressing more-to-come absolute right-0 flex w-[76px] justify-end gap-x-[4px] pr-[4px] leading-none transition-all ease-in-out dark:pr-1">
								<span className="hidden text-xs opacity-0 transition-all delay-100 ease-in-out group-hover:block group-hover:opacity-100">
									See all
								</span>
								<span className="h-[16px] w-[16px] transition-all ease-in-out">
									<Icon name="chevronRight" />
								</span>
							</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PagesAndLinks
