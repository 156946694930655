import React, {useEffect, useState} from "react";
import useSWR from "swr";
import fetcher from "~/lib/fetcher";
import getProfile from "~/utilities/profiles";
import Parse from "html-react-parser";
import {Icon} from "@twilight-toolkit/ui";
import openLink from "~/utilities/externalLink";
import Image from "next/image";
import Marquee from "~/components/Helpers/marquee";

const discord = getProfile("discord");
const twitch = getProfile("twitch");

export default function Status() {
    const { data: statusData, isLoading: statusLoading } = useSWR(
        `api/status?userId=${discord}`,
        fetcher,{
            refreshInterval: 15000 // Refresh every 60000ms (1 minute)
        }
    )
    const { data: twitchData, isLoading: twitchLoading } = useSWR(
        `api/twitch?channel=${twitch}`,
        fetcher,{
            refreshInterval: 15000 // Refresh every 60000ms (1 minute)
        }
    )

    const [ isHovered, setHovered ] = useState(false)
    const [ pauseMarquee, setPauseMarquee ] = useState(false)

    if ( !statusLoading || statusData ) {
        let label, colors, icon, link = "", show = false
        switch ( statusData.discord_status ) {
            case "online":
                show = true
                label = "Online"
                icon = {
                    name: "circleDuo",
                    classes: "ml-[-2.5px] -mr-px duo:scale-70 duo:-translate-y-px duo-primary:fill-green-500 duo-secondary:animate-subtlePulse dark:duo-primary:fill-green-500 dark:duo-secondary:animate-subtlePulse",
                    pulse: true
                }
                colors = "border-green-300 bg-green-50 text-green-500 dark:border-green-700 dark:bg-green-800 dark:text-green-400"
                break;
            case "dnd":
                show = true
                label = "Unavailable"
                icon = {
                    name: "dndDuo",
                    classes: "duo:scale-95 duo:-translate-y-px duo-primary:fill-red-50 duo-secondary:fill-red-500 dark:duo-primary:fill-red-800 dark:duo-secondary:fill-red-400",
                    pulse: false
                }
                colors = "border-red-300 bg-red-50 text-red-500 dark:border-red-700 dark:bg-red-800 dark:text-red-400 bg-gray-900/35"
                break;
            default:
                show = true
                label = "Away"
                icon = {
                    name: "moonDuo",
                    classes: "-ml-px dark:bg-gray-900/25 duo:scale-95 duo-primary:fill-amber-400 duo-secondary:fill-amber-500 dark:duo-primary:fill-amber-500 dark:duo-secondary:fill-amber-400"
                }
                colors = "border-amber-300 bg-amber-50 text-amber-500 dark:border-amber-700 dark:bg-amber-800 dark:text-amber-400"
                break;
        }

        if ( !twitchLoading && twitchData && twitchData.type === 'live' ) {
            show = true
            label = "LIVE"
            icon = {
                name: "circleDuo",
                classes: "ml-[-2.5px] -mr-px duo:scale-70 duo:-translate-y-px duo-primary:fill-purple-500 duo-secondary:animate-subtlePulse dark:duo-primary:fill-purple-500 dark:duo-secondary:animate-subtlePulse",
                pulse: true
            }
            colors = "border-purple-300 bg-purple-50 text-purple-500 dark:border-purple-700 dark:bg-purple-800 dark:text-purple-400"
            link = `https://twitch.tv/${twitch}`
        }

        return (
            <>
                {show && (
                    <span
                        onClick={() => {
                            link.length ? openLink(link) : null
                        }}
                        onMouseOver={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        className={`relative inline-flex flex-row items-center gap-x-1 rounded-full border px-2 py-0.5 text-xs ${colors} select-none ${(statusData.activities && statusData.activities.length > 0) || link.length ? 'cursor-pointer' : 'cursor-default'}`}>
                            <i className={`w-[14px] h-[14px] relative ${icon.classes} rounded-full`}>
                                <Icon name={icon.name} />
                            </i>
                            {label}
                            {!twitchLoading && twitchData && twitchData.game_name && (
                                <div
                                    onMouseOver={() => setPauseMarquee(true)}
                                    onMouseLeave={() => setPauseMarquee(false)}
                                    className={`absolute z-50 top-full w-max right-0 rounded bg-black shadow-sm text-white text-xs ${isHovered ? 'opacity-100 translate-y-1.5' : 'opacity-0 -translate-y-2'} transition-all duration-300 transform-gpu overflow-hidden pointer-events-none`}>
                                    <Image src={`${thumbDimensions(twitchData.thumbnail_url, 256, 144)}`} alt={`${twitch} live on twitch.tv`} width={256} height={144} unoptimized={true} loading="lazy" className="block w-full h-full aspect-video object-cover" />
                                    <span className="absolute bottom-0 inset-x-0 px-4.5 pb-4 pt-24 flex flex-row items-center gap-x-2.5 bg-gradient-to-t from-gray-900">
                                        <i className={`w-[16px] h-[16px]`}>
                                            <Icon name="twitch" />
                                        </i>
                                        <div className="drop-shadow flex-grow">
                                            <Marquee timing={180} speed={5} state={pauseMarquee} className="text-xs mask-x-full h-[14px] translate-y-px">
                                                <span>{Parse(twitchData.game_name)}</span>
                                            </Marquee>
                                        </div>
                                    </span>
                                </div>
                            )}
                        {statusData.activities && statusData.activities.length > 0 && (
                            <span
                                className={`flex flex-row gap-x-1.5 absolute z-50 top-full right-0 rounded bg-gray-800 dark:bg-white shadow-sm text-white dark:text-black px-2 py-0.5 text-xs ${isHovered ? 'opacity-100 translate-y-1.5' : 'opacity-0 -translate-y-2'} transition-all duration-300 transform-gpu pointer-events-none`}>
                                {statusData.activities[0].emoji && !statusData.activities[0].emoji.id && <span>{Parse(statusData.activities[0].emoji.name)}</span>}
                                {statusData.activities[0].state && <span>{Parse(statusData.activities[0].state)}</span>}
                            </span>
                        )}
                    </span>
                )}
            </>
        )
    } else {
        return <></>
    }
}

const thumbDimensions = (url, width, height) => {
    return url.replace(/{width}/g, width).replace(/{height}/g, height);
}